// 登录组件

import { useState, useEffect } from 'react'
import { message, Form, Input, Button } from 'antd'
import fun from '../../common/common_fun'
import url from '../../common/url_config'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import Test from '../test'
import logo_mini from './../../img/logo_mini.png'

function Login() {
  let [username, setUsernamer] = useState('')
  let [password, setPassword] = useState('')
  let [isLoading, setIsLoading] = useState(false)
  let [current_url, set_current_url] = useState(false)

  const handleSubmit = () => {
    if (isLoading) {
      return
    }
    if (username === '' || password === '') {
      alert('请填写账号和密码！')
      return
    }

    setIsLoading(true)
    fun['post_data'](url + 'login', { phone: username, password, is_xcx: false }).then(res => {
      if (res.status) {
        message.success('登录成功！')
        console.log(res.result)
        cookie.save('login_id', res.result['id'], { path: '/' })
        cookie.save('session_key', res.result['session_key'], { path: '/' })
        cookie.save('user_info', res.result.user_info, { path: '/' })
        setTimeout(() => {
          window.location.href = '/#/account?t=' + (new Date()).getTime()
          window.location.reload()
        }, 500)
      } else {
        alert('failed:' + JSON.stringify(res.result))
      }
      setIsLoading(false)
    })
  }

  useEffect(() => {
    set_current_url(window.location.href.indexOf('test') !== -1)
  }, [])

  if (current_url) {
    return <Test />
  }

  return <div className='login_root_box'>
    <div className='login_root_box_in'>
      <div style={{}} className='login_root'>
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ padding: '20px 0', fontWeight: 'normal', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ fontSize: 28, marginLeft: 15, color: '#825de0', }}>一卡通管理后台登录</span>
            {/* <img style={{ width: 65, height: 65, backgroundColor: '#ffffff', padding: 7, borderRadius: 10 }} src={logo_mini} /><span style={{ fontSize: 28, marginLeft: 15, color: '#825de0', }}>客户管理系统登陆</span> */}
          </h2>
        </div>
        <div style={{ width: 350, margin: '0 auto' }}>
          <Form onFinish={handleSubmit} className="login-form">
            <Form.Item>
              <Input
                size="large"
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="手机号"
                value={username}
                onChange={e => setUsernamer(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Input.Password
                size="large"
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="密码"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button disabled={isLoading} style={{ backgroundColor: '#8f6ee1', borderColor: '#8f6ee1', color: '#ffffff' }} size="large" type="primary" htmlType="submit" className="login-form-button">{isLoading ? 'Loading...' : '登陆'}</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  </div>
}
export default Login