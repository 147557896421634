// 菜单组件

import { Menu } from 'antd';
import router_menu from '../../common/router_menu';
import { useState, useEffect } from 'react'
import cookie from 'react-cookies'

const { SubMenu } = Menu

function App() {
  let [login_roles, set_login_roles] = useState([''])

  useEffect(() => {
    if ((cookie.load('login_username'))) {
      set_login_roles([...login_roles, ...cookie.load('login_role').split(',').map((item: any) => item)])
    }
  }, [])

  return (
    <div>
      <Menu theme='dark' mode="vertical">
        {router_menu.map((item: any) => {
          if (!item.hidden && (!item.only_admin || (item.only_admin && login_roles.indexOf('总管理员') !== -1))) {
            if (item.children) {
              return <SubMenu key={item.route} title={<span>{item.icon}<span style={{ color: '#ffffff' }}>{item.title}</span></span>}>
                {item.children.map((item: any) => <Menu.Item key={item.route}><span><a target={item.route === '/index' ? '_blank' : '_self'} title={item.title} href={'/#' + item.route}>{item.icon}&nbsp;&nbsp;{item.title}</a></span></Menu.Item>)}
              </SubMenu>
            } else {
              return <Menu.Item key={item.route}>{item.icon}<span><a target={item.route === '/index' ? '_blank' : '_self'} title={item.title} href={'/#' + item.route}>{item.title}</a></span></Menu.Item>
            }
          }
        })}
      </Menu >
    </div >
  )
}

export default App