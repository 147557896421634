// 公共函数

import { saveAs } from 'file-saver'
import jQuery from "jquery"
import cookie from 'react-cookies'

// post请求发送paras返回json
const post_data = (url: string, paras = {}) => {
  let params = {
    ...paras,
    login_id: cookie.load('login_id') || '',
    session_key: cookie.load('session_key') || '',
  }
  return fetch(url, {
    method: 'post',
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  }).then(res => {
    if (res.status === 200 && res.ok) {
      return res.json()
    } else {
      return ({ status: false, result: res.status })
    }
  }).catch(res => ({ status: false, result: '网络错误：' + res }))
}

// 为数组data添加索引
const add_index_for_fun = (arr: [any]) => {
  arr.map((item, index) => {
    item.index = index
    item.process_status_button = false
  })
  return arr
}

// 格式化路由数据
const format_router = (data: any) => {
  const router: any = []
  const circle = (data: any) => {
    return data.map((item: any) => {
      if (item.children) {
        circle(item.children)
      } else {
        router.push({ route: item.route, component: item.component, para: item.para ? item.para : '' })
      }
    })
  }
  circle(data)
  return router
}

// 得到y-m-d日期
const formatDate = (now: any) => {
  let y
  let m
  let d
  y = now.getFullYear();
  m = now.getMonth() + 1;
  d = now.getDate();
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
}
// 得到h-mi-s时间
const formatDate2 = (now: any) => {
  let h
  let mi
  let s
  h = now.getHours()
  mi = now.getMinutes()
  s = now.getSeconds()
  return (h < 10 ? "0" + h : h) + ':' + (mi < 10 ? "0" + mi : mi) + ':' + (s < 10 ? "0" + s : s);
}

const formateTime = (now: any) => {
  let y
  let m
  let d
  let h
  let mi
  let s
  y = now.getFullYear();
  m = now.getMonth() + 1;
  d = now.getDate();
  h = now.getHours()
  mi = now.getMinutes()
  s = now.getSeconds()
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + ' ' + (h < 10 ? "0" + h : h) + ':' + (mi < 10 ? "0" + mi : mi) + ':' + (s < 10 ? "0" + s : s);
}

// 第n年的第n周，获取开始和结束时间 （星期一为一周的开始），例如2020-1
function formate_week_date(weekstr: any) {
  let year = weekstr.split("-")[0]
  let weekNo = Number(weekstr.split("-")[1]) + 1
  // 此年1号是星期几
  let oneday = new Date(year + '-01-01').getDay() //0-6
  // 方便计算，当为星期天时为7
  if (oneday == 0) {
    oneday = 7
  }

  let one_fistday;
  let one_lastday;
  // 如果1号刚好是星期一
  if (oneday == 1) {
    one_fistday = year + '-01-01'
    one_lastday = year + '-01-07'
  } else {
    let jj = 8 - oneday
    one_fistday = (year - 1) + '-12-' + (31 - oneday + 2 > 9 ? 31 - oneday + 2 : '0' + (31 - oneday + 2))
    one_lastday = year + '-01-' + (jj > 9 ? jj : '0' + jj)
  }

  let fistday;
  let lastday;
  // 如果刚好是第一周
  if (weekNo == 1) {
    fistday = one_fistday
    lastday = one_lastday
  } else {
    fistday = addDate(one_lastday, (weekNo - 2) * 7 + 1)
    lastday = addDate(one_lastday, (weekNo - 1) * 7)
  }
  return [fistday, lastday]
}

//日期加减法  date参数为计算开始的日期，days为需要加的天数   
//格式:addDate('2017-1-11',20) 
function addDate(date: any, days: any) {
  var d = new Date(date);
  d.setDate(d.getDate() + days);
  var m = d.getMonth() + 1;
  return d.getFullYear() + '-' + (m > 9 ? m : '0' + m) + '-' + (d.getDate() > 9 ? d.getDate() : '0' + d.getDate());
}

const slice_date = (date: string) => date.slice(0, 10)

// 去除特殊字符串
const delete_string = (str: string) => {
  if (str === null || str === undefined) {
    return ''
  }
  str = str.replace(/\s*/g, "")
  return str.replace(/[\^\-\,\@\!\|\~\`\(\)\#\+\=\$\%\^\&\*\{\}\:\;\"\'\<\>\?]/g, '')
}

// 去除换行回车符
const delete_enter = (str: string) => {
  if (str === null || str === undefined) {
    return ''
  }
  str = str.replace(/\ +/g, "");
  str = str.replace(/[\r\n]/g, "");
  return str
}

// 检测输入
const test_input = {
  tel: (tel: any) => {
    const t = Number(tel)
    if (isNaN(t) || t < 13000000000 || t >= 20000000000) {
      return false
    } else {
      return true
    }
  },
  filter_string: (str: string) => {
    const arr = ["'", '"', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')',]
    let str_new = String(str)
    for (let i = 0; i < str_new.length; i++) {
      if (str_new[i] == ' ') {
        return true
      }
      for (let j = 0; j < arr.length; j++) {
        if (str_new[i] === arr[j]) {
          return true
        }
      }
    }
    return false
  },
  contain_chinese: (str: string) => {
    if (escape(str).indexOf("%u") < 0) { return false } else { return true }
  }
}

const find_value = (data: any, key1: string, value1: string) => {
  for (let i in data) {
    let item = data[i]
    if (item[key1] == value1) {
      return ({ ...item, index: i })
    }
  }
  return {}
}

const isInteger = (obj: number) => {
  return obj % 1 === 0
}

// 对比数组，找出不同。每个数组都只能是字符串数组
const match_arr = (arr_old: [any], arr_new: [any]) => {
  const add_con = []
  const delete_con = []
  for (let i in arr_old) {
    if (arr_new.indexOf(arr_old[i]) == -1) {
      delete_con.push(arr_old[i])
    }
  }
  for (let i in arr_new) {
    if (arr_old.indexOf(arr_new[i]) == -1) {
      add_con.push(arr_new[i])
    }
  }
  return { add_con, delete_con }
}

// html->word
const export_doc = () => {
  if (typeof jQuery !== "undefined" && typeof saveAs !== "undefined") {
    (function ($: any) {
      $.fn.wordExport = function (fileName: string) {
        fileName = typeof fileName !== 'undefined' ? fileName : "jQuery-Word-Export";
        var statics = {
          mhtml: {
            top: "Mime-Version: 1.0\nContent-Base: " + window.location.href +
              "\nContent-Type: Multipart/related; boundary=\"NEXT.ITEM-BOUNDARY\";type=\"text/html\"\n\n--NEXT.ITEM-BOUNDARY\nContent-Type: text/html; charset=\"utf-8\"\nContent-Location: " +
              window.location.href + "\n\n<!DOCTYPE html>\n" +
              "<html xmlns:v=\"urn:schemas-microsoft-com:vml\" xmlns:o=\"urn:schemas-microsoft-com:office:office\" xmlns:w=\"urn:schemas-microsoft-com:office:word\" xmlns:m=\"http://schemas.microsoft.com/office/2004/12/omml\" xmlns=\"http://www.w3.org/TR/REC-html40\">\n_html_</html>",
            head: "<head>\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\n<style>\n_styles_\n</style>\n<!--[if gte mso 9]><xml><w:WordDocument><w:View>Print</w:View><w:TrackMoves>false</w:TrackMoves><w:TrackFormatting/><w:ValidateAgainstSchemas/><w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid><w:IgnoreMixedContent>false</w:IgnoreMixedContent><w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText><w:DoNotPromoteQF/><w:LidThemeOther>EN-US</w:LidThemeOther><w:LidThemeAsian>ZH-CN</w:LidThemeAsian><w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript><w:Compatibility><w:BreakWrappedTables/><w:SnapToGridInCell/><w:WrapTextWithPunct/><w:UseAsianBreakRules/><w:DontGrowAutofit/><w:SplitPgBreakAndParaMark/><w:DontVertAlignCellWithSp/><w:DontBreakConstrainedForcedTables/><w:DontVertAlignInTxbx/><w:Word11KerningPairs/><w:CachedColBalance/><w:UseFELayout/></w:Compatibility><w:BrowserLevel>MicrosoftInternetExplorer4</w:BrowserLevel><m:mathPr><m:mathFont m:val=\"Cambria Math\"/><m:brkBin m:val=\"before\"/><m:brkBinSub m:val=\"--\"/><m:smallFrac m:val=\"off\"/><m:dispDef/><m:lMargin m:val=\"0\"/> <m:rMargin m:val=\"0\"/><m:defJc m:val=\"centerGroup\"/><m:wrapIndent m:val=\"1440\"/><m:intLim m:val=\"subSup\"/><m:naryLim m:val=\"undOvr\"/></m:mathPr></w:WordDocument></xml><![endif]--></head>\n",
            body: "<body>_body_</body>"
          }
        };
        var options = {
          maxWidth: 624
        };
        // Clone selected element before manipulating it
        var markup = $(this).clone();

        // Remove hidden elements from the output
        markup.each(() => {
          var self = $(this);
          if (self.is(':hidden'))
            self.remove();
        });

        // Embed all images using Data URLs
        var images = Array();
        var img: any = new Image();
        for (var i = 0; i < img.length; i++) {
          // Calculate dimensions of output image
          var w = Math.min(img[i].width, options.maxWidth);
          var h = img[i].height * (w / img[i].width);
          // Create canvas for converting image to data URL
          var canvas: any = document.createElement("CANVAS");
          canvas.width = w;
          canvas.height = h;
          // Draw image to canvas
          var context = canvas.getContext('2d');
          context.drawImage(img[i], 0, 0, w, h);
          // Get data URL encoding of image
          var uri = canvas.toDataURL("image/png");
          $(img[i]).attr("src", img[i].src);
          img[i].width = w;
          img[i].height = h;
          // Save encoded image to array
          images[i] = {
            type: uri.substring(uri.indexOf(":") + 1, uri.indexOf(";")),
            encoding: uri.substring(uri.indexOf(";") + 1, uri.indexOf(",")),
            location: $(img[i]).attr("src"),
            data: uri.substring(uri.indexOf(",") + 1)
          };
        }

        // Prepare bottom of mhtml file with image data
        var mhtmlBottom = "\n";
        for (var i = 0; i < images.length; i++) {
          mhtmlBottom += "--NEXT.ITEM-BOUNDARY\n";
          mhtmlBottom += "Content-Location: " + images[i].location + "\n";
          mhtmlBottom += "Content-Type: " + images[i].type + "\n";
          mhtmlBottom += "Content-Transfer-Encoding: " + images[i].encoding + "\n\n";
          mhtmlBottom += images[i].data + "\n\n";
        }
        mhtmlBottom += "--NEXT.ITEM-BOUNDARY--";

        //TODO: load css from included stylesheet
        var styles = "";

        // Aggregate parts of the file together
        var fileContent = statics.mhtml.top.replace("_html_", statics.mhtml.head.replace("_styles_", styles) +
          statics.mhtml.body.replace("_body_", markup.html())) + mhtmlBottom;

        // Create a Blob with the file contents
        var blob = new Blob([fileContent], {
          type: "application/msword;charset=utf-8"
        });
        saveAs(blob, fileName + ".doc");
      };
      $("#main_content").wordExport("导出")
    })(jQuery);
  } else {
    if (typeof jQuery === "undefined") {
      console.error("jQuery Word Export: missing dependency (jQuery)");
    }
    if (typeof saveAs === "undefined") {
      console.error("jQuery Word Export: missing dependency (FileSaver.js)");
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  post_data,
  add_index_for_fun,
  format_router,
  formatDate,
  formatDate2,
  test_input,
  delete_string,
  slice_date,
  formateTime,
  find_value,
  isInteger,
  match_arr,
  delete_enter,
  export_doc,
  formate_week_date,
}