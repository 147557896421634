// 电脑端入口页

import { useState, useEffect } from 'react'
import RouterConfig from './common/router_config'
import { HashRouter } from 'react-router-dom'
import './styles/common.css'
import './App.css'
import 'antd/dist/antd.css'
import Login from './components/login/index'
import Menu from './components/menu/index'
import { ConfigProvider, BackTop } from 'antd'
import { UpCircleOutlined, PoweroffOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons';
import url from './common/url_config'
import fun from './common/common_fun'
import zhCN from 'antd/es/locale/zh_CN';
import logo_mini from './img/logo_mini2.png'
import cookie from 'react-cookies'

function App() {
  let [current_url, set_current_url] = useState(false)
  let [username, set_username] = useState('')
  let [login_role, set_login_role] = useState('')
  let [login_name, set_login_name] = useState('')

  useEffect(() => {
    if ((cookie.load('user_info'))) {
      set_username(cookie.load('user_info')['账号'])
      set_login_role(cookie.load('user_info')['角色'])
    }
  }, [])

  const log_out = () => {
    let is_confirm = window.confirm('确认退出登陆？')
    if (!is_confirm) {
      return
    }
    fun['post_data'](url + 'admin_logout').then(res => {
      console.log(res)
      cookie.remove('user_info')
      cookie.remove('session_key')
      window.location.href = '/#/'
      window.location.reload()
    })
  }

  if (!username) {
    return <Login />
  }

  return (
    <div className='main_root'>
      <BackTop>
        <div style={{ backgroundColor: '#1088e9', color: 'white', opacity: 0.5, width: 40, height: 40, textAlign: 'center', lineHeight: '48px' }} ><UpCircleOutlined style={{ fontSize: 25 }} /></div>
      </BackTop>
      <HashRouter>
        <div>
          {!current_url && <div style={{ width: 140, position: 'fixed', left: 0, top: 0, height: '100%', backgroundColor: '#001529', zIndex: 1000 }}>
            <div style={{ borderBottom: '4px solid #002a52', color: '#ffffff', marginBottom: 6 }}>
              <div style={{ textAlign: 'center', height: 50, color: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ width: 32, height: 32, borderRadius: 5 }} src={logo_mini} /><span style={{ fontSize: 15, marginLeft: 10, fontWeight: 'bold' }}>
                一卡通管理
                </span>
              </div>
            </div>
            <Menu />
            <div style={{ color: '#ffffff', marginTop: 30, marginLeft: 10, width: 165 }}>
              <div style={{ marginTop: 5 }}><UserOutlined /> {login_role} {username}</div>
              <div style={{ cursor: 'pointer', marginTop: 5 }} onClick={() => log_out()}><PoweroffOutlined /> 退出登陆</div>
            </div>
          </div>}
          <div style={{ width: '100%', backgroundColor: '', marginTop: 0, overflow: 'auto', }}>
            <div style={{ width: current_url ? '100%' : 'calc(100% - 140px)', float: 'right', }} className='router'>
              <ConfigProvider locale={zhCN}><RouterConfig /></ConfigProvider>
            </div>
          </div>
        </div>
      </HashRouter>
    </div>
  );
}

export default App;