// 测试页面

import { useEffect } from 'react'
import AMapLoader from '@amap/amap-jsapi-loader';
import '../../styles/common.css'

let mode = 'china' //china world

let data_china = [
  {
    name: "广东省",
    position: [113.280637, 23.125178],
    value: 10000,
  },
  {
    name: "河南省",
    position: [113.665412, 34.757975],
    value: 5000,
  },
  {
    name: "海南省",
    position: [110.33119, 20.031971],
    value: 3000,
  },
  {
    name: "江苏省",
    position: [118.767413, 32.041544],
    value: 1000,
  },
];
let data_world = [
  { "name": "美国", "value": "8000", position: [262.877840, 42.579829] },
  { "name": "中国", "value": "79404", position: [99.8544644, 36.78778] },
  { "name": "俄罗斯", "value": "2000", position: [68.565, 62.132427] },
  { "name": "加拿大", "value": "600", position: [241.358370, 58.5714368] },
  { "name": "英国", "value": "200", position: [-4.5595980, 53.57656] },
  { "name": "法国", "value": "50", position: [3.6581753, 48.76021] },
];
let data = mode == 'china' ? data_china : data_world

function App() {
  useEffect(() => {
    AMapLoader.load({
      "key": "da54ede2ff53c9737a7fe78dfe94dba8",
      "version": "2.0",
      "plugins": [],
      "Loca": {
        "version": '2.0'
      },
    }).then((AMap) => {
      var counts = [10000, 5000, 1000, 500, 100];
      var color = ['#6917d1', '#2d6bf5', '#3eafd4', '#6dfe82', '#b2ffbd'];
      var dis = new AMap.DistrictLayer[mode == 'china' ? 'Country' : 'World']({
        zIndex: 10,
        SOC: mode == 'china' ? 'CHN' : '',
        depth: 1,
        styles: {
          'stroke-width': 0.8,
          'fill': function (d) {
            var country = data.find(c => {
              return c.name == d.NAME_CHN;
            });
            if (!country) {
              return '#fff';//填充无数据国家颜色
            }
            country = country.value;
            if (country > counts[0]) {
              return color[0];
            } else if (country > counts[1]) {
              return color[1];
            } else if (country > counts[2]) {
              return color[2];
            } else if (country > counts[3]) {
              return color[3];
            } else {
              return color[4];
            }
          },
          'coastline-stroke': function (d) {
            if (d.type === 'Coastline_China') {
              return '#41ae76';
            }
            return 'rgba(0,0,0,0)';
          },
          'nation-stroke': function (d) {
            return '#8d8d8d';
          },
        }
      })

      var map = new AMap.Map('container', {
        layers: [dis],
        center: mode == 'china' ? [106.122082, 33.719192] : [170.451348, 43.792165],
        zoom: mode == 'china' ? 3.5 : 1,
      });
      var ul = document.querySelector('.legend ul');
      ul.innerHTML = '';
      color.forEach((c, i) => {
        ul.innerHTML +=
          '<li><span style="background: ' + c + ';"></span><a> > ' + counts[i] + '</a></li>';
      });

      map.on('complete', function () {
        let LabelsData = []
        for (let i = 0; i < data.length; i++) {
          let config = {
            name: '',
            position: data[i]['position'],
            zIndex: 1,
            opacity: 1,
            text: {
              content: '',
              direction: 'center',
              offset: [0, 0],
              zooms: [0, 100],
              style: {
                fontSize: 16,
                fontWeight: 'bold',
                fillColor: '#565656',
                strokeColor: '#ffffff',
                strokeWidth: 4,
              }
            }
          };
          let district = data[i];
          let name = district.name;
          config.text.content = name + ' ' + district.value;
          LabelsData.push(config);
        }
        var layer = new AMap.LabelsLayer({
          collision: false,
          animation: true,
        });
        for (var i = 0; i < LabelsData.length; i++) {
          var labelsMarker = new AMap.LabelMarker(LabelsData[i]);
          layer.add(labelsMarker);
        }
        map.add(layer);
      })
    }).catch(e => {
      console.log(e);
    })
  }, [])

  return (<div>
    <div style={{ width: 500, height: 400 }} id="container" className="map" tabIndex="0"></div>
    <div className="legend" id="legend">
      <ul></ul>
    </div>
  </div>)
}


export default App;