// 配置路由文件

import { AppstoreOutlined, MoneyCollectOutlined, FundViewOutlined, PlusSquareOutlined, AccountBookOutlined, SettingOutlined, SmileOutlined, GiftOutlined, TagsOutlined, SolutionOutlined, MonitorOutlined, CarryOutOutlined, TeamOutlined, GlobalOutlined, BarChartOutlined } from '@ant-design/icons';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    title: '业务统计',
    route: '/admin_index',
    icon: <BarChartOutlined />,
  },
  {
    title: '收益提现记录',
    route: '/account',
    icon: <AccountBookOutlined />,
  },
  {
    title: '用户管理',
    route: '/manager_client',
    icon: <TeamOutlined />,
  },
  {
    title: '费用设定',
    route: '/admin_basic_info/price_setting',
    icon: <SettingOutlined />,
  },
  {
    title: '商家行业管理',
    route: '/admin_basic_info/merchant_type',
    icon: <AppstoreOutlined />,
  },
]


